import React from 'react';

import {v4 as uuidv4} from 'uuid';
import styled from 'styled-components';
import { Welcome } from './Welcome';
import { CAN_I_SWITCH, OPEN_VIEW_EVENT } from '../../../auto/js/events/Gui';

import { RibbonTab, setContextualOptions } from '../../../auto/js/widgets';

import { ToolBar } from '../widgets/ToolBar';

import '@trendmicro/react-breadcrumbs/dist/react-breadcrumbs.css';
import '@trendmicro/react-buttons/dist/react-buttons.css';
import '@trendmicro/react-dropdown/dist/react-dropdown.css';
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/css/bootstrap.min.css";
import './base.css';

const Main = styled.main`
    position: relative;
    overflow: hidden;
    transition: all .15s;
    height: calc(100vh - 100px);
	overflow-y: auto;
	direction: ltr;
	margin-top: 20px;
	margin-bottom: 20px;
`;

const welcomeUUID = uuidv4();

export class MainPage extends React.Component {
	constructor(props) {
		super(props);
        this.views = new Map();
		this.requestingClose = false
		let v = {uuid: welcomeUUID, view: () => <Welcome key={welcomeUUID}/>};
		this.register(v);
		this.state = {
			focusedView: v,
		};
		OPEN_VIEW_EVENT.subscribe((v) => 
		{
			if (!this.requestingClose)
				this.canISwitchTo(v)
		});
    }

	register = (v) => {
		this.views.set(v.uuid, v);
	}
	
	unregister = (v) => {
		setContextualOptions({});
		this.views.delete(v.uuid);
	}

	canISwitchTo = async (v) => {
		if (this.state.focusedView) {
			this.requestingClose = true;
			CAN_I_SWITCH.send(this.doSwitchTo(v), () => this.doSwitchTo(v)(true));
		}
	}

	doSwitchTo = (v) => (closeCurrent) => {
		if (closeCurrent)
			this.switchView(v)
		this.requestingClose = false;
	}

	switchView = function (v) {
		// default behavior for now is one view at a time
		// this will eventually change though
		if (this.state.focusedView) {
			if (this.state.focusedView.uuid==v.uuid)
				return;
			else {
				this.unregister(this.state.focusedView);
				CAN_I_SWITCH.hangUp();
			}
		}
		if (!this.views.has(v.uuid))
			this.register(v);
		this.setState({focusedView:v});
		this.requestingClose = false;
	}
	
	buildWorkspace = () => {
		let items = [];
		for (const [uuid, v] of this.views.entries()) {
			items.push(v.view());
		}
		return items;
	}
	
	render() {
		return (
			<div>
				<ToolBar languages={this.props.languages}/>
				<Main>
					{this.buildWorkspace()}
				</Main>
			</div>
		);
	}
}