export const pojoMetadata = {
			'country': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "flag", name: "flag", editable: true, resizable: true, type: "blob" },
			   	{ key: "flagMimeType", name: "flag_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.flagMimeType == "")
					data.flagMimeType = null;
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'audit-log': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "actionTime", name: "action_time", editable: true, resizable: true, type: "time" },
			   	{ key: "actionType", name: "action_type", editable: true, resizable: true, type: "text" },
			   	{ key: "rowPk", name: "row_pk", editable: true, resizable: true, type: "number" },
			   	{ key: "tableName", name: "table_name", editable: true, resizable: true, type: "text" },
			   	{ key: "userId", name: "user_id", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.actionType == "")
					data.actionType = null;
				if(data.tableName == "")
					data.tableName = null;
				if(data.userId == "")
					data.userId = null;
				return data
			}
		},
		'occupation-metadata-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'occupation-metadata-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'i18n': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "domainId", name: "domain_id", editable: true, resizable: true, type: "text" },
			   	{ key: "localeId", name: "locale_id", editable: true, resizable: true, type: "text" },
			   	{ key: "messageId", name: "message_id", editable: true, resizable: true, type: "text" },
			   	{ key: "value", name: "value", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.domainId == "")
					data.domainId = null;
				if(data.localeId == "")
					data.localeId = null;
				if(data.messageId == "")
					data.messageId = null;
				if(data.value == "")
					data.value = null;
				return data
			}
		},
		'phone-number-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "phoneNumber", name: "phone_number", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.phoneNumber == "")
					data.phoneNumber = null;
				return data
			}
		},
		'country-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "flag", name: "flag", editable: true, resizable: true, type: "blob" },
			   	{ key: "flagMimeType", name: "flag_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.flagMimeType == "")
					data.flagMimeType = null;
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'issued-documents': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "documentId", name: "document_id", editable: true, resizable: true, type: "number" },
			   	{ key: "documentType", name: "document_type", editable: true, resizable: true, type: "number" },
			   	{ key: "face", name: "face", editable: true, resizable: true, type: "blob" },
			   	{ key: "isVoter", name: "is_voter", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			   	{ key: "residenceDepartment", name: "residence_department", editable: true, resizable: true, type: "text" },
			   	{ key: "residenceMunicipality", name: "residence_municipality", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "votingCenter", name: "voting_center", editable: true, resizable: true, type: "text" },
			   	{ key: "votingDepartment", name: "voting_department", editable: true, resizable: true, type: "text" },
			   	{ key: "votingMunicipality", name: "voting_municipality", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.notes == "")
					data.notes = null;
				if(data.residenceDepartment == "")
					data.residenceDepartment = null;
				if(data.residenceMunicipality == "")
					data.residenceMunicipality = null;
				if(data.votingCenter == "")
					data.votingCenter = null;
				if(data.votingDepartment == "")
					data.votingDepartment = null;
				if(data.votingMunicipality == "")
					data.votingMunicipality = null;
				return data
			}
		},
		'occupation-metadata': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'country-trash': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "flag", name: "flag", editable: true, resizable: true, type: "blob" },
			   	{ key: "flagMimeType", name: "flag_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.flagMimeType == "")
					data.flagMimeType = null;
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'locations': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "activeLocation", name: "active_location", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "customLocation", name: "custom_location", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "districtCode", name: "district_code", editable: true, resizable: true, type: "text" },
			   	{ key: "districtName", name: "district_name", editable: true, resizable: true, type: "text" },
			   	{ key: "islandCode", name: "island_code", editable: true, resizable: true, type: "text" },
			   	{ key: "islandName", name: "island_name", editable: true, resizable: true, type: "text" },
			   	{ key: "locationCode", name: "location_code", editable: true, resizable: true, type: "text" },
			   	{ key: "locationName", name: "location_name", editable: true, resizable: true, type: "text" },
			   	{ key: "pollingStationCode", name: "polling_station_code", editable: true, resizable: true, type: "number" },
			   	{ key: "pollingStationName", name: "polling_station_name", editable: true, resizable: true, type: "text" },
			   	{ key: "provinceCode", name: "province_code", editable: true, resizable: true, type: "text" },
			   	{ key: "provinceName", name: "province_name", editable: true, resizable: true, type: "text" },
			   	{ key: "ruralLocation", name: "rural_location", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "zoneCode", name: "zone_code", editable: true, resizable: true, type: "text" },
			   	{ key: "zoneName", name: "zone_name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.districtCode == "")
					data.districtCode = null;
				if(data.districtName == "")
					data.districtName = null;
				if(data.islandCode == "")
					data.islandCode = null;
				if(data.islandName == "")
					data.islandName = null;
				if(data.locationCode == "")
					data.locationCode = null;
				if(data.locationName == "")
					data.locationName = null;
				if(data.pollingStationName == "")
					data.pollingStationName = null;
				if(data.provinceCode == "")
					data.provinceCode = null;
				if(data.provinceName == "")
					data.provinceName = null;
				if(data.zoneCode == "")
					data.zoneCode = null;
				if(data.zoneName == "")
					data.zoneName = null;
				return data
			}
		},
		'phone-number': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "phoneNumber", name: "phone_number", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.phoneNumber == "")
					data.phoneNumber = null;
				return data
			}
		},
		'email-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "email", name: "email", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.email == "")
					data.email = null;
				return data
			}
		},
		'user-preferences-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "userId", name: "user_id", editable: true, resizable: true, type: "text" },
			   	{ key: "value", name: "value", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.key == "")
					data.key = null;
				if(data.userId == "")
					data.userId = null;
				if(data.value == "")
					data.value = null;
				return data
			}
		},
		'email': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "civilStatusMtlbId", name: "civil_status_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "deathRecordId", name: "death_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "email", name: "email", editable: true, resizable: true, type: "text" },
			   	{ key: "vitalRecordId", name: "vital_record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.email == "")
					data.email = null;
				return data
			}
		},
		'locations-history': {
			columns: [
			   	{ key: "hsno", name: "hsno", editable: false, resizable: true},
			   	{ key: "activeLocation", name: "active_location", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "customLocation", name: "custom_location", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "districtCode", name: "district_code", editable: true, resizable: true, type: "text" },
			   	{ key: "districtName", name: "district_name", editable: true, resizable: true, type: "text" },
			   	{ key: "id", name: "id", editable: true, resizable: true, type: "number" },
			   	{ key: "islandCode", name: "island_code", editable: true, resizable: true, type: "text" },
			   	{ key: "islandName", name: "island_name", editable: true, resizable: true, type: "text" },
			   	{ key: "locationCode", name: "location_code", editable: true, resizable: true, type: "text" },
			   	{ key: "locationName", name: "location_name", editable: true, resizable: true, type: "text" },
			   	{ key: "pollingStationCode", name: "polling_station_code", editable: true, resizable: true, type: "text" },
			   	{ key: "pollingStationName", name: "polling_station_name", editable: true, resizable: true, type: "text" },
			   	{ key: "provinceCode", name: "province_code", editable: true, resizable: true, type: "text" },
			   	{ key: "provinceName", name: "province_name", editable: true, resizable: true, type: "text" },
			   	{ key: "ruralLocation", name: "rural_location", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "zoneCode", name: "zone_code", editable: true, resizable: true, type: "text" },
			   	{ key: "zoneName", name: "zone_name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.districtCode == "")
					data.districtCode = null;
				if(data.districtName == "")
					data.districtName = null;
				if(data.islandCode == "")
					data.islandCode = null;
				if(data.islandName == "")
					data.islandName = null;
				if(data.locationCode == "")
					data.locationCode = null;
				if(data.locationName == "")
					data.locationName = null;
				if(data.pollingStationCode == "")
					data.pollingStationCode = null;
				if(data.pollingStationName == "")
					data.pollingStationName = null;
				if(data.provinceCode == "")
					data.provinceCode = null;
				if(data.provinceName == "")
					data.provinceName = null;
				if(data.zoneCode == "")
					data.zoneCode = null;
				if(data.zoneName == "")
					data.zoneName = null;
				return data
			}
		},
		'user-preferences': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "userId", name: "user_id", editable: true, resizable: true, type: "text" },
			   	{ key: "value", name: "value", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.key == "")
					data.key = null;
				if(data.userId == "")
					data.userId = null;
				if(data.value == "")
					data.value = null;
				return data
			}
		},
}

export const buildEmptyObject = (entity) => {
	var columns = new Object();
	let fields = pojoMetadata[entity].columns
	for (let col in fields) {		
		if(fields[col].type == 'text'){
			columns[fields[col].key] = "";
		}else if(fields[col].type=='checkbox'){
			columns[fields[col].key] = false;
		}else if(fields[col].type=='datetime-local'){
			columns[fields[col].key] = "";
		}
		else if(fields[col].type=='autocomplete'){
			columns[fields[col].key] = null;
		}
	 }
	return columns
};

export const getEntityIdCol = (entity) => {
	return pojoMetadata[entity].columns[0].key;
}

export const getRowId = (entity, row) => {
	return row[getEntityIdCol(entity)];
}

