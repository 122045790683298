import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { t, rest } from '../../../auto/js/services';

import toolbarStyles from './ToolBar.styl';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export function ToolBar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar color="inherit" className={toolbarStyles.toolbar} >
        <div style={{ marginRight: 'auto'}}></div>
            <Button
            color="inherit"
            className={toolbarStyles.navbarBtn}
            onClick={() => {rest.oidcService.logout();}}>
            {t`Sign-out`}
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}
