import React, { useState } from "react";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: 500,
    height: 200,
    marginBottom: 20,
    marginLeft: 20,
  },
  action: {
    '&:hover': {
        background: "#E5E8E8",
    }
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 250,
  },
}));

export default function MediaCard(props) {
  const [id, setId] = useState(props.id)
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Card className={classes.root}>
      <CardActionArea className = {classes.action} onClick={() => props.onClick(id)}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5">
            {props.title}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {props.subTitle}
          </Typography>
        </CardContent>
      </div>
      </CardActionArea>

      <CardMedia
        className={classes.cover}
        image={(props.image)?props.image:null}
        title=""
      />
    </Card>
  );
}